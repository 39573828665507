import { Empty, Skeleton, Tabs } from 'antd';
import _ from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as CandidateConnectActions from '../../Actions/CandidateConnectActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import * as JobActions from '../../Actions/JobActions';
import ContentPaneBody from '../../Components/Connect/ContentPaneBody/ContentPaneBody';
import ContentPaneHeader from '../../Components/Connect/ContentPaneHeader/ContentPaneHeader';
import ConversationListItem from '../../Components/Connect/ConversationListItem/ConversationListItem';
import PrimaryListHeader from '../../Components/Connect/PrimaryListHeader/PrimaryListHeader';
import SecondaryListHeader from '../../Components/Connect/SecondaryListHeader/SecondaryListHeader';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getRecentReadNotificationInfo } from '../../Reducers/AryaNotifyReducer';
import { getCallConversations } from '../../Reducers/CallConversationsReducer';
import { getCandidateCount, getCandidates, getConnectDetails } from '../../Reducers/CandidateReducer';
import {
  getConversationStatsForJob,
  getJobGuidToIdMapping,
  getJobsById,
  getJobsCountByStatus,
  getJobsIdsByStatus,
  getMustHaves,
  getNiceToHaves,
  getSubscriptionStats,
} from '../../Reducers/JobReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import BotConfigDrawerContainer from '../BotConfigDrawerContainer/BotConfigDrawerContainer';
import CallDialogContainer from '../CallDialog/CallDialog';
import CareerportalConversationsContainer from '../CareerportalConversationsContainer/CareerportalConversationsContainer';
import TemplatesContainer from '../TemplatesContainer/TemplatesContainer';
import WorkflowContainer from '../WorkflowContainer/WorkflowContainer';

import ComposeEmailAndMessageButton from '../../Components/Connect/ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import ConnectV2Redirect from '../../Components/Connect/ConnectV2/ConnectV2Redirect';
import DripConfigSwitch from '../../Components/Connect/DripConfigSwitch/DripConfigSwitch';
import jobMessage from '../../Components/JobForm/JobMessages';
import PageNotAllowed from '../../Components/PageNotAllowed/PageNotAllowed';
import UnsubscribeCandidateButton from '../../Components/UnsubscribeCandidate/UnsubsCribeCandidateButton';
import UnsubscribedButton from '../../Components/UnsubscribeCandidate/UnsubscribedButton';
import { AryaBotIcon, EmptyCandidatesIcon, EmptyConnectIcon } from '../../Icons/AryaIcons';
import { getConfig, getConnectConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import {
  getCandidatesConnectInfo,
  getConversationsById,
  getCreditsRefunded,
  getDripStats,
  getJobsUnreadConversationCount,
  getOrgLevelPersonDetailsById,
  getOrglevelConversationsById,
  getOrglevelConversationsCount,
} from '../../Reducers/ConnectReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getAllMailCount, getMailCount } from '../../Reducers/MailConversationsReducer';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { getKeyWordsToHighlight } from '../../Utils/CandidateListUtils';
import { getInternalSourceWithCandidateId } from '../../Utils/SourceUtils';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import WorkflowTemplateCreationContainer from '../WorkflowTemplateSelection/WorkflowTemplateSelectionContainer';
import './Connect.scss';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  jobsIdsByStatus: getJobsIdsByStatus(state),
  JobGuidToIdMapping: getJobGuidToIdMapping(state),
  userConfig: getConfig(state),
  jobsById: getJobsById(state),
  jobsCountByStatus: getJobsCountByStatus(state),
  _getCandidatesById: jobId => getCandidates(state, jobId),
  connectInfo: getCandidatesConnectInfo(state),
  getPersonalityStatus: getApiStatus(state, 'getPersonalityStatus'),
  fetchPersonalityStatus: getApiStatus(state, 'fetchPersonalityStatus'),
  conversationsById: getConversationsById(state),
  jobsUnreadConversationCount: getJobsUnreadConversationCount(state),
  resumeUnlockStatus: getApiStatus(state, 'resumeUnlockStatus'),
  connectConfig: getConnectConfig(state),
  candidateListApiStatus: getApiStatus(state, 'candidateListStatus'),
  candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
  usersById: getUsersById(state),
  featureToggleList: getFeatureToggleList(state),
  candidatesTotal: getCandidateCount(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  connectDetails: getConnectDetails(state),
  creditsRefunded: getCreditsRefunded(state),
  conversationStatsForJob: getConversationStatsForJob(state),
  subscriptionStatsForJob: getSubscriptionStats(state),
  candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
  jobListApiStatus: getApiStatus(state, 'jobListStatus'),
  searchConversationApiStatus: getApiStatus(state, 'searchConversationApiStatus'),
  recentReadNotificationInfo: getRecentReadNotificationInfo(state, 'Connect'),
  getJobMustHaves: jobId => getMustHaves(state, jobId),
  getJobNiceToHaves: jobId => getNiceToHaves(state, jobId),
  currentUserDetails: getCurrentUserDetails(state),
  orgLevelConversationsFetchApiStatus: getApiStatus(state, 'fetchOrgLevelConversations'),
  orgLevelPersonDetailsById: getOrgLevelPersonDetailsById(state),
  orglevelConversationsById: getOrglevelConversationsById(state),
  orglevelConversationsCount: getOrglevelConversationsCount(state),
  dripConfigApiStatus: getApiStatus(state, 'dripConfigApiStatus'),
  dripStats: getDripStats(state),
  _getCallConversations: selectedCandidateId => getCallConversations(state, selectedCandidateId),
  allContextualMailCount: personId => getAllMailCount(state, personId),
  contextualMailCount: conversationId => getMailCount(state, conversationId),
  contextualMailsConversationApiStatus: getApiStatus(state, 'setContextualMailsConversationApiStatus'),
  allContextualMailsConversationApiStatus: getApiStatus(state, 'setAllContextualMailsConversationApiStatus'),
});

const mapDispatchToProps = {
  downloadResume: CandidateActions.downloadResume,
  fetchConnectedJobs: JobActions.fetchConnectedJobs,
  fetchJobList: JobActions.fetchConnectedJobs,
  fetchJobCount: JobActions.fetchJobCount,
  fetchContactedCandidates: CandidateActions.fetchContactedCandidates,
  fetchCandidateDetails: CandidateActions.fetchCandidateDetails,
  unlockCandidateResume: CandidateActions.unlockCandidateResume,
  postCandidateNotes: CandidateActions.postCandidateNotes,
  fetchPersonality: ConnectActions.fetchPersonality,
  fetchCallNotes: CandidateConnectActions.fetchCallNotes,
  getConversationReference: ConnectActions.getConversationReference,
  fetchCandidateNotes: CandidateConnectActions.fetchCandidateNotes,
  fetchContact: ConnectActions.fetchContact,
  addContact: ConnectActions.addContact,
  getRefundForInvalidContacts: ConnectActions.getRefundForInvalidContacts,
  pushCandidateToConnectAndFetchContact: ConnectActions.pushCandidateToConnectAndFetchContact,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  setCurrentJobId: JobActions.setCurrentJobId,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchEmailStats: ConnectActions.fetchEmailStats,
  setCandidateRejectStatus: CandidateActions.setCandidateRejectStatus,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
  saveRecentReadNotificationInfo: AryaNotifyActions.saveRecentReadNotificationInfo,
  resetCandidates: CandidateActions.resetCandidates,
  fetchJobDetails: JobActions.fetchJobDetails,
  searchOrgLevelConversations: ConnectActions.searchOrgLevelConversations,
  fetchEmailTemplates: ConnectActions.fetchEmailTemplates,
  fetchMails: ConnectActions.fetchMails,
};

class ConnectContainer extends React.Component {
  constructor(props) {
    super(props);
    const activeTab = props.defaultActiveTab || 'conversations';
    this.connectMessageRef = React.createRef();
    this.connectALLMessageRef = React.createRef();
    this.connectMailRef = React.createRef();
    this.connectAllMailRef = React.createRef();
    this.state = {
      selectedJob: {
        Guid: null,
        Id: null,
        JobTitle: null,
      },
      selectedCandidate: {
        Id: null,
      },
      activeTab,
      showBotConfigDrawer: false,
      candidateDrawerVisiblity: false,
      isJobSearched: false,
      isOrgConversationsFilterApplied: false,
      orgConversationsFilterValues: [],
      isEmailStatsFilterApplied: false,
      isShortlistedByFilterApplied: false,
      emailStatsfilterValues: [],
      shortlistedByFilterValues: [],
      drawerRenderFlag: false,
      subTabKey: 'profile',
      isComposeEmailModalVisible: false,
      isComposeMessageModalVisible: false,
      activeConnectContentTab: 'email',
      currentJobDetails: {},
      isDripActive: false,
      isCandidateUnsubscribed: false,
      workflowSelectedEmails: [],
      isComposeWorkflowModalVisible: false,
      currentSelectedEmail: null,
    };

    this.onJobClick = this.onJobClick.bind(this);
    this.onCandidateClick = this.onCandidateClick.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.showCandidateDrawer = this.showCandidateDrawer.bind(this);
    this.hideCandidateDrawer = this.hideCandidateDrawer.bind(this);
    this.onCandidateStatusChange = this.onCandidateStatusChange.bind(this);
    this.handleJobSearch = this.handleJobSearch.bind(this);
    this.handleCandidateSearch = this.handleCandidateSearch.bind(this);
    this.loadMoreJobs = this.loadMoreJobs.bind(this);
    this.loadMoreCandidates = this.loadMoreCandidates.bind(this);
    this.onClickAtsPush = this.onClickAtsPush.bind(this);
    this.getCandidateList = this.getCandidateList.bind(this);
    this.postAtsCallNotes = this.postAtsCallNotes.bind(this);
    this.onApplyEmailStatsFilter = this.onApplyEmailStatsFilter.bind(this);
    this.setEmailStatsFilterStatus = this.setEmailStatsFilterStatus.bind(this);
    this.setShortlistedByFilterStatus = this.setShortlistedByFilterStatus.bind(this);
    this.setEmailStatsFilterValues = this.setEmailStatsFilterValues.bind(this);
    this.setShortlistedByFilterValues = this.setShortlistedByFilterValues.bind(this);
    this.setWorkflowSelectedEmails = this.setWorkflowSelectedEmails.bind(this);
    this.setWorkflowComposeModalVisibility = this.setWorkflowComposeModalVisibility.bind(this);
    this.setCurrentSelectedEmail = this.setCurrentSelectedEmail.bind(this);
  }

  componentDidMount() {
    this.setIsCandidateUnsubscribed();
    this.fetchconnectDetails();
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const activeKey = searchParams.get('activeTab');
    if (activeKey === 'message') {
      this.onConnectContentTabClick(activeKey);
    }
  }

  fetchconnectDetails() {
    const { fetchJobList, fetchJobCount, fetchEmailConfig, recentReadNotificationInfo } = this.props;
    const { RefId } = recentReadNotificationInfo;

    const filter = {
      count: 10,
      skip: 0,
      connectStatus: 'contacted',
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: false,
    };
    if (RefId) filter.jobGuids = [RefId];
    fetchJobList(filter);
    fetchJobCount(filter);
    fetchEmailConfig({ isAdmin: false });
    fetchEmailConfig({ isAdmin: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      jobsById,
      JobGuidToIdMapping,
      _getCandidatesById,
      jobListApiStatus,
      candidateListApiStatus,
      recentReadNotificationInfo,
      saveRecentReadNotificationInfo,
      dripConfigApiStatus,
      dripStats,
      connectInfo,
      location,
    } = this.props;

    const { selectedJob, selectedCandidate } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);
    const candidate = _.get(candidatesById, [selectedCandidate.Id], {});
    const { recentReadNotificationInfo: prevRecentReadNotificationInfo } = prevProps;
    const { RefId: prevRefId, notificationId: prevNotificationId } = prevRecentReadNotificationInfo;

    const {
      RefId: currentRefId,
      ConversationId: currConversationId,
      notificationId: currNotificationId,
    } = recentReadNotificationInfo;
    const nextjobListApiStatus = jobListApiStatus;
    const prevjobListApiStatus = prevProps.jobListApiStatus;
    const nextCandidateListApiStatus = candidateListApiStatus;
    const prevCandidateListApiStatus = prevProps.candidateListApiStatus;
    if (selectedCandidate?.ConversationId) {
      const conversationDripStats = dripStats?.[selectedCandidate?.ConversationId];
      const scheduledEmailsCount = conversationDripStats?.reduce(
        (totalScheduledDripCount, currentTemplateDripStats) => {
          return totalScheduledDripCount + currentTemplateDripStats.ScheduledEmailsCount;
        },
        0
      );
      const isDripActivated = dripConfigApiStatus !== 'FAILED' && scheduledEmailsCount > 0;
      if (prevState.isDripActive !== isDripActivated) {
        this.setIsDripActive(isDripActivated);
      }
    }
    if (currentRefId && (currentRefId !== prevRefId || currNotificationId !== prevNotificationId)) {
      this.fetchconnectDetails();
    }

    if (
      (currentRefId && prevjobListApiStatus !== 'COMPLETED' && nextjobListApiStatus === 'COMPLETED') ||
      (recentReadNotificationInfo.PersonId &&
        recentReadNotificationInfo.PersonId !== prevProps.recentReadNotificationInfo.PersonId)
    ) {
      const jobId = JobGuidToIdMapping[currentRefId];
      const job = jobsById[jobId];
      saveRecentReadNotificationInfo('Connect', { jobId: job.JobId });
      this.onJobClick(job.JobGuid, job.JobId, job.JobTitle, currConversationId);
    }

    if (
      (currentRefId && prevCandidateListApiStatus !== 'COMPLETED' && nextCandidateListApiStatus === 'COMPLETED') ||
      (recentReadNotificationInfo.PersonId &&
        recentReadNotificationInfo.PersonId !== prevProps.recentReadNotificationInfo.PersonId)
    ) {
      const jobId = JobGuidToIdMapping[currentRefId];
      const job = jobsById[jobId];
      const candidates = Object.values(candidatesById) || [];

      const reqCandidate = candidates.find(candidate => candidate.ConversationId === currConversationId);

      this.onCandidateClick(jobId, reqCandidate?.Id, currConversationId, job.JobGuid);
    }

    const { selectedCandidate: prevSelectedCandidate } = prevState;
    const selectedCandidateId = selectedCandidate.Id;
    const prevSelectedCandidateId = prevSelectedCandidate.Id;

    const previousSubscriptionStatus =
      prevProps.connectInfo?.ConnectStatuses?.[candidate?.PersonId]?.Contact?.Subscription?.Status;
    const currentSubscriptionStatus =
      connectInfo?.ConnectStatuses?.[candidate?.PersonId]?.Contact?.Subscription?.Status;

    if (selectedCandidateId !== prevSelectedCandidateId || previousSubscriptionStatus !== currentSubscriptionStatus) {
      this.setState({
        isCandidateUnsubscribed:
          connectInfo?.ConnectStatuses?.[candidate?.PersonId]?.Contact?.Subscription?.Status?.toLowerCase() ===
          'unsubscribed',
      });
    }

    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get('activeTab');
    if (
      recentReadNotificationInfo?.ConversationId !== prevProps.recentReadNotificationInfo?.ConversationId &&
      (activeTab === 'message' || activeTab === 'email')
    ) {
      this.onConnectContentTabClick(activeTab);
    }
  }

  setIsDripActive(value) {
    this.setState({ isDripActive: value }, () => { });
  }

  setEmailComposeModalVisibility = value => {
    this.setState({
      isComposeEmailModalVisible: value,
    });
  };

  setWorkflowComposeModalVisibility = value => {
    this.setState({
      isComposeWorkflowModalVisible: value,
    });
  };

  setCurrentSelectedEmail = value => {
    this.setState({
      currentSelectedEmail: value,
    });
  };

  setWorkflowSelectedEmails = value => {
    this.setState({
      workflowSelectedEmails: value,
    });
  };

  setMessageComposeModalVisibility = value => {
    this.setState({
      isComposeMessageModalVisible: value,
    });
  };

  onConnectContentTabClick = activeKey => {
    this.setState({
      activeConnectContentTab: activeKey,
    });
  };

  onTabChange(key) {
    const { setUrl, location } = this.props;
    const { pathname } = location;
    const keyToUrl = {
      conversations: `${pathname}?tab=conversations`,
      aryabot: `${pathname}?tab=aryabot`,
      templates: `${pathname}?tab=templates`,
      workflows: `${pathname}?tab=workflows`,
    };
    this.setState({ activeTab: key });
    setUrl(keyToUrl[key]);
  }

  componentWillUnmount() {
    const { saveRecentReadNotificationInfo, resetCandidates } = this.props;
    saveRecentReadNotificationInfo('Connect', {});
    resetCandidates();
  }

  fetchConnectCandidates({ jobId, jobGuid, emailStatuses, shortlistedBy }, conversationId = null) {
    const { fetchContactedCandidates } = this.props;
    const filter = {
      jobId,
      jobGuid,
      Statuses: shortlistedBy?.length > 0 ? ['shortlisted'] : [],
      ConnectionStatus: 'Contacted',
      From: 0,
      Size: 10,
      ShortlistedBy: shortlistedBy,
      EmailStats: emailStatuses,
      IsConnectedV2: false,
    };
    if (conversationId) {
      filter.conversationId = conversationId;
    }
    fetchContactedCandidates(filter);
  }

  async onJobClick(guid, id, title, conversationId = null) {
    const { getConversationReference, jobClickCallback, jobsById, setCurrentJobId, fetchJobDetails } = this.props;
    setCurrentJobId(id);
    this.fetchConnectCandidates({ jobId: id, jobGuid: guid }, conversationId);
    getConversationReference(id, guid);
    if (jobClickCallback) jobClickCallback(_.get(jobsById, [id, 'JobCode'], -1));
    this.setState({
      selectedJob: {
        Guid: guid,
        Id: id,
        JobTitle: title,
      },
      candidateSearchTerm: undefined,
      selectedCandidate: {
        Id: null,
      },
    });
    this.setEmailStatsFilterStatus(false);
    this.setShortlistedByFilterStatus(false);
    this.setEmailStatsFilterValues([]);
    this.setShortlistedByFilterValues([]);
    const jobDetails = await fetchJobDetails(id);
    this.setCurrentJobDetails({ jobDetails });
  }

  onCandidateClick(jobId, id, conversationId, currentJobGuid) {
    this.setState({
      selectedCandidate: {
        JobId: jobId,
        Id: id,
        ConversationId: conversationId,
        currentJobGuid,
      },
    });
  }

  onCandidateStatusChange(filter, size) {
    const { selectedJob, selectedCandidate } = this.state;
    const { Id: jobId } = selectedJob;
    const { Id: candidateId } = selectedCandidate;
    const { changeCandidateStatus } = this.props;
    const { candidate, status, rejectReasons, includeSimilar, mustHaves: _mustHaves } = filter;
    const mustHaves = _mustHaves?.split(',') ?? [];
    changeCandidateStatus({
      status,
      jobId,
      candidateId,
      reasons: rejectReasons,
      mustHaves,
      includeSimilar,
      jobTitle: this.getJobTitle(),
    });
    if (status !== 'Shortlisted' && (status === 'Rejected' || candidate.Status === 'Rejected')) {
      this.onCandidateReject(candidate, size);
    }
  }

  getJobTitle = () => {
    const { jobsById } = this.props;
    const { selectedJob } = this.state;
    return _.get(jobsById, [selectedJob.Id, 'JobTitle'], '');
  };

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { selectedJob, showBotConfigDrawer, botDrawerRenderFlag } = this.state;
    if (selectedJob && selectedJob.Id) {
      if (showBotConfigDrawer) this.setState({ showBotConfigDrawer: !showBotConfigDrawer });
      else this.setState({ showBotConfigDrawer: !showBotConfigDrawer, botDrawerRenderFlag: !botDrawerRenderFlag });
    }
  };

  showCandidateDrawer(tab) {
    const { fetchCandidateDetails, fetchCallNotes, fetchCandidateNotes, _getCandidatesById, jobsById } = this.props;
    const { selectedCandidate, selectedJob } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);
    const candidateDetails = _.get(candidatesById, [selectedCandidate.Id], {});
    fetchCandidateDetails(
      {
        jobId: selectedJob.Id,
        candidateId: selectedCandidate.Id,
      },
      { refId: selectedJob.Guid, Country: jobsById[selectedJob.Id].CountryCode }
    );
    if (tab === 'notes') {
      fetchCandidateNotes({
        jobId: selectedJob.Id,
        candidateId: selectedCandidate.Id,
      });
      fetchCallNotes(selectedCandidate.Id, candidateDetails.ConversationId, 0, 10);
    }
    this.setState({
      candidateDrawerVisiblity: true,
      drawerRenderFlag: true,
      subTabKey: tab,
    });
  }

  hideCandidateDrawer() {
    this.setState({
      candidateDrawerVisiblity: false,
    });
  }

  handleJobSearch(value) {
    const { fetchJobList, fetchJobCount } = this.props;
    this.setState({
      jobSearchTerm: value,
      isJobSearched: true,
    });
    const filter = {
      count: 10,
      skip: 0,
      connectStatus: 'contacted',
      searchTerm: value,
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: true,
    };
    fetchJobList(filter);
    fetchJobCount(filter);
  }

  handleCandidateSearch(value) {
    const { selectedJob } = this.state;
    if (selectedJob.Id) {
      const { fetchContactedCandidates } = this.props;
      this.setState({
        candidateSearchTerm: value,
        selectedCandidate: {
          Id: null,
        },
      });
      fetchContactedCandidates({
        jobId: selectedJob.Id,
        jobGuid: selectedJob.Guid,
        Statuses: [],
        ConnectionStatus: 'Contacted',
        SearchQuery: value,
        From: 0,
        Size: 10,
        IsConnectedV2: false,
      });
    }
  }

  getCandidateList(candidates, candidatesTotal) {
    const { selectedJob, candidateSearchTerm, selectedCandidate } = this.state;
    const { candidateListApiStatus, conversationsById } = this.props;
    if (!selectedJob.Guid) {
      return (
        <Empty
          description={
            <span>
              <b>
                {' '}
                <FormattedMessage {...jobMessage.noJobSelectedLabel} />
              </b>
              <br />
              <FormattedMessage {...jobMessage.selectAJobToSeeActiveConversationsLabel} />
            </span>
          }
          style={{ marginTop: 100 }}
          image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
        />
      );
    }

    if (candidateListApiStatus === 'INPROGRESS' || candidates.length) {
      return (
        <Skeleton loading={candidateListApiStatus === 'INPROGRESS' && !candidates.length}>
          <InfiniteScroll
            pageStart={1}
            key={`${selectedJob.Guid} ${candidateSearchTerm || ''}`}
            loadMore={this.loadMoreCandidates}
            initialLoad={false}
            hasMore={
              candidateListApiStatus !== 'INPROGRESS' &&
              candidatesTotal !== undefined &&
              candidates.length < candidatesTotal
            }
            useWindow={false}
            loader="Loading..."
          >
            {candidates.map(candidate => (
              <ConversationListItem
                key={candidate.Id}
                title={candidate.Name}
                primaryInfo={`${candidate.Title || ''} ${candidate.Title && candidate.Company ? '@' : ''} ${candidate.Company || ''
                  }`}
                secondaryInfo={candidate.Location}
                onClick={() =>
                  this.onCandidateClick(selectedJob.Id, candidate.Id, candidate.ConversationId, selectedJob.Guid)
                }
                isActive={selectedCandidate.Id === candidate.Id}
                isoDateTime={_.get(candidate, 'RecentConversationTime')}
                badgeCount={
                  _.get(conversationsById, [candidate.ConversationId, 'UnreadSmsCount'], 0) +
                  _.get(conversationsById, [candidate.ConversationId, 'UnreadChatCount'], 0)
                }
              />
            ))}
          </InfiniteScroll>
        </Skeleton>
      );
    }
    return (
      <Empty
        image={<EmptyCandidatesIcon className="connect-candidate-list-empty-icon" />}
        description={
          <span>
            <b>
              <FormattedMessage {...jobMessage.noCandidatesFoundInfo} />
            </b>
            <br />
            <FormattedMessage {...jobMessage.connectedCandidatesInfo} />
          </span>
        }
        className="connect-candidate-list-empty"
      />
    );
  }

  loadMoreJobs(page) {
    const { fetchJobList } = this.props;
    const { jobSearchTerm } = this.state;
    const filter = {
      count: 10,
      skip: (page - 1) * 10,
      connectStatus: 'contacted',
      searchTerm: jobSearchTerm,
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: false,
    };
    fetchJobList(filter, 'ON_LOAD');
  }

  loadMoreCandidates(page) {
    const { fetchContactedCandidates } = this.props;
    const { selectedJob, candidateSearchTerm, emailStatuses, shortlistedBy } = this.state;
    if (selectedJob.Id) {
      fetchContactedCandidates(
        {
          jobId: selectedJob.Id,
          jobGuid: selectedJob.Guid,
          Statuses: shortlistedBy?.length > 0 ? ['shortlisted'] : [],
          ConnectionStatus: 'Contacted',
          ShortlistedBy: shortlistedBy,
          SearchQuery: candidateSearchTerm,
          From: (page - 1) * 10,
          Size: 10,
          EmailStats: emailStatuses,
          IsConnectedV2: false,
        },
        'ON_LOAD'
      );
    }
  }

  onClickAtsPush(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal) {
    const { openAtsView } = this.props;
    if (openAtsView) openAtsView(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal);
  }

  postAtsCallNotes(note, candidateId) {
    const { postAtsCallNotes, jobsById, _getCandidatesById } = this.props;
    const { selectedJob } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);

    const sources = _.get(candidatesById, ['Internal', candidateId, 'Sources'], []);
    const internalSource = getInternalSourceWithCandidateId(sources);

    postAtsCallNotes(note, _.get(internalSource, ['CandidateId'], 0), _.get(jobsById, [selectedJob.id, 'JobCode'], -1));
  }

  openCandidateView = (candidate, jobIdToOpen) => {
    const { openAryaCandidateView } = this.props;
    const { selectedCandidate } = this.state;
    const { JobId: currentJobId, Id } = selectedCandidate;
    const JobId = jobIdToOpen || currentJobId;
    if (openAryaCandidateView) openAryaCandidateView(JobId, Id, '3');
    else window.open(`/jobs/${JobId}/candidates/${Id}?v=3`);
  };

  onApplyEmailStatsFilter(emailStatuses, shortlistedBy) {
    const { selectedJob } = this.state;
    this.fetchConnectCandidates({
      jobId: selectedJob.Id,
      jobGuid: selectedJob.Guid,
      shortlistedBy,
      emailStatuses,
    });
    this.setState({
      emailStatuses,
      shortlistedBy,
      selectedCandidate: {
        Id: null,
      },
    });
  }

  setEmailStatsFilterStatus(isEmailStatsFilterApplied) {
    this.setState({
      isEmailStatsFilterApplied,
    });
  }

  setShortlistedByFilterStatus(isShortlistedByFilterApplied) {
    this.setState({
      isShortlistedByFilterApplied,
    });
  }

  setOrgConversationFilterStatus = isOrgConversationsFilterApplied => {
    this.setState({
      isOrgConversationsFilterApplied,
    });
  };

  setOrgConversationFilterValues = orgConversationsFilterValues => {
    this.setState({ orgConversationsFilterValues });
  };

  setEmailStatsFilterValues(emailStatsfilterValues) {
    this.setState({ emailStatsfilterValues });
  }

  setShortlistedByFilterValues(shortlistedByFilterValues) {
    this.setState({ shortlistedByFilterValues });
  }

  toggleDrawerRenderFlag = () => {
    const { drawerRenderFlag } = this.state;
    this.setState({ drawerRenderFlag: !drawerRenderFlag });
  };

  onCandidateReject = (candidate, size) => {
    const { setCandidateRejectStatus } = this.props;
    setCandidateRejectStatus(candidate.Id, size);
  };

  keywordsToHighlight = memoize(
    (mustHaves, niceToHaves, currentJobDetails = {}) =>
      getKeyWordsToHighlight({ mustHaves, niceToHaves, currentJobDetails }).allkeywordsToHighlight
  );
  // To be confirmed

  setCurrentJobDetails = ({ jobDetails }) => {
    this.setState({ currentJobDetails: jobDetails });
  };

  setIsCandidateUnsubscribed = () => {
    const { _getCandidatesById, connectInfo } = this.props;

    const { selectedJob, selectedCandidate } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);
    const candidate = _.get(candidatesById, [selectedCandidate.Id], {});

    this.setState({
      isCandidateUnsubscribed:
        connectInfo?.ConnectStatuses?.[candidate?.PersonId]?.Contact?.Subscription?.Status?.toLowerCase() ===
        'unsubscribed',
    });
  };

  render() {
    const {
      jobsIdsByStatus,
      jobsById,
      _getCandidatesById,
      conversationsById,
      jobsCountByStatus,
      connectInfo,
      jobsUnreadConversationCount,
      userConfig,
      dialingTone,
      ringingTone,
      callNotesContainer,
      version,
      featureToggleList,
      candidatesTotal,
      whiteLabelInfo,
      connectDetails,
      conversationStatsForJob,
      subscriptionStatsForJob,
      candidateDetailsStatus,
      candidateListApiStatus,
      candidateAllNotesFetchStatus,
      notesContainer,
      recentReadNotificationInfo,
      downloadResume,
      saveRecentReadNotificationInfo,
      jobListApiStatus,
      setUrl,
      location,
      openSipCallWindowsApp,
      getJobMustHaves,
      getJobNiceToHaves,
      currentUserDetails,
      searchOrgLevelConversations,
      orgLevelConversationsFetchApiStatus,
      orgLevelPersonDetailsById,
      orglevelConversationsById,
      orglevelConversationsCount,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      dripConfigApiStatus,
      _getCallConversations,
      fetchEmailTemplates,
      fetchMails,
      allContextualMailCount,
      contextualMailCount,
      contextualMailsConversationApiStatus,
      allContextualMailsConversationApiStatus,
    } = this.props;

    const {
      selectedJob,
      selectedCandidate,
      activeTab,
      showBotConfigDrawer,
      candidateDrawerVisiblity,
      jobSearchTerm,
      isJobSearched,
      botDrawerRenderFlag,
      isEmailStatsFilterApplied,
      isShortlistedByFilterApplied,
      emailStatsfilterValues,
      subTabKey,
      drawerRenderFlag,
      isComposeEmailModalVisible,
      isComposeMessageModalVisible,
      activeConnectContentTab,
      currentJobDetails,
      orgConversationsFilterValues,
      isOrgConversationsFilterApplied,
      isDripActive,
      shortlistedByFilterValues,
      workflowSelectedEmails,
      isComposeWorkflowModalVisible,
      currentSelectedEmail,
    } = this.state;

    const candidatesById = _getCandidatesById(selectedJob.Id);
    const jobIds = jobsIdsByStatus.contacted || [];
    const jobDetails = jobsById[selectedJob.Id];
    const jobs = jobIds.map(jobId => jobsById[jobId]);
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const jobsTotal = _.get(jobsCountByStatus, 'contacted', undefined);
    const candidates = Object.values(candidatesById) || [];
    const candidate = _.get(candidatesById, [selectedCandidate.Id], {});
    const candidateDetails = _.get(candidatesById, [selectedCandidate.Id], {});
    const connectStatuses = connectInfo.ConnectStatuses || {};
    const connectStatus = candidateDetails ? _.get(connectStatuses, candidateDetails.PersonId, {}) : {};
    const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    const jobCountryCode = _.get(jobsById, [selectedJob.Id, 'CountryCode']);
    const mustHaves = getJobMustHaves(selectedJob.Id);
    const callConversations = _getCallConversations(selectedCandidate.ConversationId);
    const niceToHaves = getJobNiceToHaves(selectedJob.Id);
    const { connectMessageRef, connectALLMessageRef, connectMailRef, connectAllMailRef } = this;
    const selectedFromEmail = connectMailRef.current?.getCurrentSelectedFromEmail() || {};
    const { selectedEmail } = connectMailRef.current?.state || {};

    const contextualMessageCount = connectMessageRef.current?.state.messages?.length || 0;
    const isMessageCommunicationAllowed = connectMessageRef.current?.state?.isCommunicationAllowed;
    const isMessageComposeButtonVisible =
      activeConnectContentTab === 'message' && contextualMessageCount && isMessageCommunicationAllowed;

    const contextualEmailCount = connectMailRef.current?.state.allEmails?.length || 0;
    const isMailCommunicationAllowed = connectMailRef.current?.state?.isCommunicationAllowed;
    const { setDefaultEmailTemplateValues } = connectMailRef.current || {};
    const { setCurrentReplyingToEmail } = connectMailRef.current || {};
    const { setSubjectDisability } = connectMailRef.current || {};
    const isEmailComposeButtonVisible =
      activeConnectContentTab === 'email' && contextualEmailCount && isMailCommunicationAllowed;
    const isComposeButtonVisible = isEmailComposeButtonVisible || isMessageComposeButtonVisible;
    const { isCandidateUnsubscribed } = this.state;
    const isUnsubscribeCandidateEnabled = featureToggleList.UnsubscribeCandidate.IsEnabled;
    const unsubscribeCandidateButton = !isCandidateUnsubscribed ? (
      <UnsubscribeCandidateButton candidate={candidate} />
    ) : (
      <UnsubscribedButton candidate={candidate} />
    );

    const {
      IsCareerportalChatViewEnabled: { IsEnabled: isCareerportalChatViewEnabled },
    } = featureToggleList;
    const isWorkflowWindowEnabled = featureToggleList.CandidateLevelWorkflow.IsEnabled;

    const alternateState = jobsIdsByStatus.contacted ? (
      <Empty
        description={
          <div>
            <b>
              <FormattedMessage {...jobMessage.noConversationFoundLabel} />
            </b>
            <br />
            <FormattedMessage {...jobMessage.candidatesWithWhomYouStartedLabel} />
          </div>
        }
        style={{ marginTop: '24vh' }}
        image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
      />
    ) : (
      <Skeleton />
    );

    let conversationsTab =
      _.get(jobsIdsByStatus, ['contacted', 'length'], false) || isJobSearched ? (
        <div className="connect-container">
          <div className="main-column grid-column" id="grid-1">
            <PrimaryListHeader
              count={jobsTotal !== undefined ? jobsTotal.toString() : '-'}
              onSearch={this.handleJobSearch}
              recentReadNotificationInfo={recentReadNotificationInfo}
              saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
            />
            <div className="card-list">
              {jobsIdsByStatus.contacted.length ? (
                <InfiniteScroll
                  pageStart={1}
                  key={jobSearchTerm || 1}
                  loadMore={this.loadMoreJobs}
                  hasMore={jobListApiStatus !== 'INPROGRESS' && jobsTotal !== undefined && jobIds.length < jobsTotal}
                  useWindow={false}
                  initialLoad={false}
                  loader="Loading..."
                >
                  {jobs.map(job => (
                    <ConversationListItem
                      key={job.JobGuid}
                      jobCode={job.JobCode}
                      isActive={selectedJob.Guid === job.JobGuid}
                      title={job.JobTitle}
                      primaryInfo={job.Client}
                      secondaryInfo={job.JobId.toString()}
                      isoDateTime={_.get(jobsUnreadConversationCount, [job.JobGuid, 'RecentConversationTime'])}
                      onClick={() => this.onJobClick(job.JobGuid, job.JobId, job.JobTitle)}
                      badgeCount={_.get(jobsUnreadConversationCount, [job.JobGuid, 'UnreadConversationCount'], 0)}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <Empty
                  description={
                    <span>
                      <b>
                        <FormattedMessage {...jobMessage.noJobsFoundLabel} />
                      </b>
                    </span>
                  }
                  style={{ marginTop: 100 }}
                  image={
                    <img
                      src={`${process.env.PUBLIC_URL}/static/Images/empty-jobs.svg`}
                      height="100px"
                      width="115px"
                      alt={<FormattedMessage {...jobMessage.noJobsFoundLabel} />}
                    />
                  }
                />
              )}
            </div>
          </div>
          <div className="sub-column grid-column" id="grid-2">
            <SecondaryListHeader
              count={candidatesTotal !== undefined ? candidatesTotal.toString() : '-'}
              onSearch={this.handleCandidateSearch}
              conversationStatsForJob={conversationStatsForJob}
              subscriptionStatsForJob={subscriptionStatsForJob}
              onApplyEmailStatsFilter={this.onApplyEmailStatsFilter}
              selectedJobId={selectedJob.Id}
              isEmailStatsFilterApplied={isEmailStatsFilterApplied}
              isShortlistedByFilterApplied={isShortlistedByFilterApplied}
              setEmailStatsFilterStatus={this.setEmailStatsFilterStatus}
              setShortlistedByFilterStatus={this.setShortlistedByFilterStatus}
              emailStatsfilterValues={emailStatsfilterValues}
              shortlistedByFilterValues={shortlistedByFilterValues}
              onEmailStatsFilterChange={this.setEmailStatsFilterValues}
              onShortlistedByFilterChange={this.setShortlistedByFilterValues}
              recentReadNotificationInfo={recentReadNotificationInfo}
              saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
              connectDetails={connectDetails}
              isLoading={candidateListApiStatus === 'INPROGRESS'}
            >
              {featureToggleList.BotRead.IsEnabled ? (
                <div className="botIcon">
                  <AryaBotIcon
                    onClick={this.toggleBotConfigDrawer}
                    style={{
                      color: _.get(jobsById, [selectedJob.Id, 'IsBotEnabled'], false) ? '#01cd75' : '#83878c',
                    }}
                  />
                </div>
              ) : null}
            </SecondaryListHeader>
            <div className="card-list candidate">{this.getCandidateList(candidates, candidatesTotal)}</div>
          </div>
          <div className="content-column grid-column" id="grid-3">
            {selectedCandidate.Id ? (
              <div>
                <ContentPaneHeader
                  candidateDetails={_.get(candidatesById, [selectedCandidate.Id], {})}
                  showProfile={this.showCandidateDrawer}
                />
                <div className="content-pane-and-compose-email-button">
                  <ContentPaneBody
                    showBotConfigDrawer={this.toggleBotConfigDrawer}
                    candidate={_.get(candidatesById, [selectedCandidate.Id], {})}
                    currentJobDetails={selectedJob}
                    jobId={selectedCandidate.JobId}
                    jobGuid={selectedCandidate.currentJobGuid}
                    availableProviders={availableProviders}
                    smsCount={_.get(conversationsById, [selectedCandidate.ConversationId, 'UnreadSmsCount'], 0)}
                    chatCount={_.get(conversationsById, [selectedCandidate.ConversationId, 'UnreadChatCount'], 0)}
                    isBotEnabled={featureToggleList.BotRead.IsEnabled}
                    FromEmailAddress={recentReadNotificationInfo.FromAddress}
                    jobCountryCode={jobCountryCode}
                    version={version}
                    openSipCallWindowsApp={openSipCallWindowsApp}
                    isComposeEmailModalVisible={isComposeEmailModalVisible}
                    isComposeMessageModalVisible={isComposeMessageModalVisible}
                    setEmailComposeModalVisibility={this.setEmailComposeModalVisibility}
                    setMessageComposeModalVisibility={this.setMessageComposeModalVisibility}
                    activeConnectContentTab={activeConnectContentTab}
                    onConnectContentTabClick={this.onConnectContentTabClick}
                    connectMessageRef={connectMessageRef}
                    connectALLMessageRef={connectALLMessageRef}
                    connectMailRef={connectMailRef}
                    connectAllMailRef={connectAllMailRef}
                    unsubscribeCandidateButton={unsubscribeCandidateButton}
                    isWorkflowWindowEnabled={isWorkflowWindowEnabled}
                    featureToggleList={featureToggleList}
                    workflowSelectedEmails={workflowSelectedEmails}
                    setWorkflowSelectedEmails={this.setWorkflowSelectedEmails}
                    isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
                    currentSelectedEmail={currentSelectedEmail}
                    setCurrentSelectedEmail={this.setCurrentSelectedEmail}
                    setWorkflowComposeModalVisibility={this.setWorkflowComposeModalVisibility}
                    isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
                    callConversations={callConversations}
                    fetchEmailTemplates={fetchEmailTemplates}
                    fetchMails={fetchMails}
                    allContextualMailCount={allContextualMailCount}
                    contextualMailCount={contextualMailCount}
                    contextualMailsConversationApiStatus={contextualMailsConversationApiStatus}
                    allContextualMailsConversationApiStatus={allContextualMailsConversationApiStatus}
                    openCandidateView={this.openCandidateView}
                  />
                  {isComposeButtonVisible && !isCandidateUnsubscribed ? (
                    <div className="compose-email-message-button-360">
                      <div className="unsubscribe-and-compose-email-button">
                        {isUnsubscribeCandidateEnabled ? unsubscribeCandidateButton : null}
                        <ComposeEmailAndMessageButton
                          setComposeModalVisibility={
                            activeConnectContentTab === 'email'
                              ? this.setEmailComposeModalVisibility
                              : this.setMessageComposeModalVisibility
                          }
                          activeTab={activeConnectContentTab}
                          setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
                          setCurrentReplyingToEmail={setCurrentReplyingToEmail}
                          setSubjectDisability={setSubjectDisability}
                          shouldChangeMailValues
                        />
                      </div>
                      {isDripActive && activeConnectContentTab === 'email' ? (
                        <div
                          style={{
                            display: 'flex',
                            borderTop: '1px solid #dadbdd',
                            alignItems: 'center',
                            width: '100%',
                            paddingBottom: '5px',
                            paddingLeft: '15px',
                          }}
                        >
                          <div className="disclaimer-message">
                            <div>
                              <InfoCircleIcon />
                            </div>
                            <b>
                              <u>
                                <FormattedMessage {...jobMessage.noteLabel} />:
                              </u>
                            </b>{' '}
                            <FormattedMessage {...jobMessage.dripWorkflowWillStopOnceCandidateRespondsLabel} />
                          </div>

                          <div className="cancel-drip-email">
                            <DripConfigSwitch
                              dripConfigStatus={dripConfigApiStatus}
                              conversationId={candidate.ConversationId}
                              selectedFromEmail={selectedFromEmail}
                              isVisible={!!selectedEmail?.EmailAddress && !featureToggleList.PaidJobService.IsEnabled}
                              candidate={candidate}
                              isDripActive={isDripActive}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <Empty
                description={
                  <span>
                    <b>
                      <FormattedMessage {...jobMessage.noCandidateSelectedLabel} />
                    </b>
                    <br />
                    <FormattedMessage {...jobMessage.selectACandidateToSeeTheConversationHistoryLabel} />
                  </span>
                }
                style={{ marginTop: 152 }}
                image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
              />
            )}
          </div>
        </div>
      ) : (
        alternateState
      );

    if (featureToggleList.Connect.IsAllowed === false) {
      conversationsTab = <PageNotAllowed page="connect" />;
    }

    const atsJobCode = _.get(jobsById, [selectedJob.Id, 'JobCode'], 0);
    const candidateSources = _.get(candidateDetails, ['Sources'], undefined);
    const atsCandidateId = _.get(getInternalSourceWithCandidateId(candidateSources), ['CandidateId'], 0);
    const displayWorkflowContainer =
      activeTab === 'workflows' ? <WorkflowContainer setUrl={setUrl} location={location} /> : null;
    const displayChooseWorkflowContainer = location?.state?.chooseDefaultWorkflow ? (
      <WorkflowTemplateCreationContainer location={location} />
    ) : null;
    const displayCareerportalConversationsContainer =
      activeTab === 'careerPortalConversations' ? (
        <CareerportalConversationsContainer
          featureToggleList={featureToggleList}
          currentUserDetails={currentUserDetails}
          searchConversations={searchOrgLevelConversations}
          orgLevelConversationsFetchApiStatus={orgLevelConversationsFetchApiStatus}
          orgLevelPersonDetailsById={orgLevelPersonDetailsById}
          orglevelConversationsById={orglevelConversationsById}
          orglevelConversationsCount={orglevelConversationsCount}
          setOrgConversationFilterValues={this.setOrgConversationFilterValues}
          setOrgConversationFilterStatus={this.setOrgConversationFilterStatus}
          orgConversationsFilterValues={orgConversationsFilterValues}
          isOrgConversationsFilterApplied={isOrgConversationsFilterApplied}
        />
      ) : null;

    return (
      <>
        {version === 'ats' ? (
          <CallDialogContainer
            callNotesContainer={callNotesContainer}
            atsJobCode={atsJobCode}
            atsCandidateId={atsCandidateId}
            postAtsCallNotes={this.postAtsCallNotes}
            dialingTone={dialingTone}
            ringingTone={ringingTone}
            openSipCallWindowsApp={openSipCallWindowsApp}
          />
        ) : null}
        <div className="connect-container-wrapper">
          <BotConfigDrawerContainer
            jobTitle={this.getJobTitle()}
            visible={showBotConfigDrawer}
            onClose={this.toggleBotConfigDrawer}
            jobId={selectedJob.Id}
            botDrawerRenderFlag={botDrawerRenderFlag}
            toggleBotDrawerRenderFlag={this.toggleBotDrawerRenderFlag}
            AppName={AppName}
          />
          <CandidateDrawerWrapper
            drawerRenderFlag={drawerRenderFlag}
            subTabKey={subTabKey}
            version={version}
            onTabClick={this.onDrawerTabChange}
            toggleDrawerRenderFlag={this.toggleDrawerRenderFlag}
            onClose={this.hideCandidateDrawer}
            visible={candidateDrawerVisiblity}
            candidateId={selectedCandidate.Id}
            candidate={candidateDetails}
            currentJobDetails={selectedJob}
            jobId={selectedJob.Id}
            jobGuid={selectedJob.Guid}
            candidateDetailsStatus={candidateDetailsStatus}
            onCandidateReject={this.onCandidateReject}
            onCandidateStatusChange={this.onCandidateStatusChange}
            fetchCandidateAllDetails={this.fetchCandidateAllDetails}
            toggleBotConfigDrawer={this.toggleBotConfigDrawer}
            onClickAtsPush={this.onClickAtsPush}
            callNotesContainer={callNotesContainer}
            notesContainer={notesContainer}
            dialingTone={dialingTone}
            ringingTone={ringingTone}
            onPageChange={this.onPageChange}
            unlockCandidateResume={this.unlockResume}
            showPushCandidateButton={false}
            showContactInfoButton={featureToggleList.ContactFetch.IsEnabled}
            aryaVersion={userConfig.SubscriptionType}
            candidateAllNotesFetched={candidateAllNotesFetched}
            isConnectOptionsEnabled
            isJobActionsAllowed={!!selectedJob.Id}
            openCandidateView={this.openCandidateView}
            showNextPrevButtons={false}
            closable={false}
            showCandidateDetailTabs={false}
            downloadResume={downloadResume}
            jobCountryCode={jobCountryCode}
            openSipCallWindowsApp={openSipCallWindowsApp}
            keywordsToHighlight={this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails)}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            onClickAddJobCallBack={onClickAddJobCallBack}
            isConnectTab
          />
          {displayChooseWorkflowContainer}

          {displayChooseWorkflowContainer ? null : (
            <div className="connect-tab-wrapper">
              <ConnectV2Redirect isVisible={featureToggleList.ConnectV2.IsEnabled} />
              <Tabs
                tabBarStyle={{ marginBottom: '24px' }}
                defaultActiveKey="conversations"
                onTabClick={this.onTabChange}
                activeKey={activeTab}
              >
                <TabPane tab={<FormattedMessage {...jobMessage.conversationsLabel} />} key="conversations">
                  {activeTab === 'conversations' ? conversationsTab : null}
                </TabPane>
                {isCareerportalChatViewEnabled && (
                  <TabPane tab="Career Portal Conversations" key="careerPortalConversations">
                    {displayCareerportalConversationsContainer}
                  </TabPane>
                )}
                <TabPane tab={<FormattedMessage {...jobMessage.templatesLabel} />} key="templates">
                  {activeTab === 'templates' ? (
                    <TemplatesContainer
                      isBotEnabled={featureToggleList.BotRead.IsEnabled}
                      AppName={AppName}
                      version={version}
                      jobDetails={jobDetails}
                      isCandidate360View
                    />
                  ) : null}
                </TabPane>
                {featureToggleList.Workflow.IsEnabled ? (
                  <TabPane tab={<FormattedMessage {...jobMessage.workflowsLabel} />} key="workflows">
                    {displayWorkflowContainer}
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
          )}
        </div>
      </>
    );
  }
}

ConnectContainer.propTypes = {
  jobsIdsByStatus: PropTypes.shape({}),
  jobsById: PropTypes.shape({}),
};

ConnectContainer.defaultProps = {
  jobsIdsByStatus: {},
  jobsById: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectContainer));

export { ConnectContainer as ConnectContainerWithoutStore };
