import { connectV1Client } from './BaseRepository';

export function fetchConnectStatuses(jobGuid, candidatePersonIds) {
  return connectV1Client.post(`Conversations/_connectstatus`, {
    RefId: jobGuid,
    PersonIds: candidatePersonIds,
  });
}

export function updateScheduledEmailsStatus(conversationIds, status) {
  return connectV1Client.post(`Conversations/_scheduledConversations`, {
    ConversationIds: conversationIds,
    Status: status,
  });
}

export function fetchDripStats(conversationId) {
  return connectV1Client.post(`EmailConversations/_dripEmailStats`, {
    ConversationId: conversationId,
  });
}

export function fetchBulkCallNotes(filter) {
  return connectV1Client.post(`Notes/_bulksearch`, filter);
}

export function fetchConnectStatus(conversationId) {
  return connectV1Client.get(`Conversations/${conversationId}/_connectstatus`);
}

export function pushPerson(personInfo, RefId) {
  return connectV1Client.post(`persons?RefId=${RefId || ''}`, {
    Name: personInfo.Name,
    Title: personInfo.Title,
    Company: personInfo.Company,
    City: personInfo.City,
    State: personInfo.State,
    Location: personInfo.Location,
    School: personInfo.School,
    Degree: personInfo.Degree,
    Country: personInfo.Country,
    Urls: (personInfo.Sources || []).map(s => s.Url),
    CorrelationId: personInfo.CorrelationId,
  });
}

export function fetchMessages({ conversationId, personId, messageType, toNumber, from, size }) {
  return connectV1Client.post(`MessageConversations/_search`, {
    From: from,
    Size: size,
    ConversationId: conversationId,
    PersonId: personId,
    MessageType: messageType,
    ToNumber: toNumber,
  });
}

export function fetchLatestMessages({ conversationId, personId, messageType, requestStatus, userId, from, size }) {
  return connectV1Client.post(`MessageConversations/_stats`, {
    From: from,
    Size: size,
    ConversationId: conversationId,
    PersonId: personId,
    MessageType: messageType,
    IncludeDrip: false,
    RequestStatus: requestStatus,
    UserId: userId,
  });
}

export function sendMessage(conversationId, message) {
  return connectV1Client.post(`Conversations/${conversationId}/MessageConversations`, {
    ...message,
    ConversationId: conversationId,
  });
}

export function fetchMails(payload) {
  return connectV1Client.post(`EmailConversations/_search`, {
    PersonId: payload.personId,
    ConversationId: payload.conversationId,
    FromOrToEmail: payload.emailAddress,
    From: payload.from,
    Size: payload.size,
  });
}

export function fetchMailsByEmailConversationId({ emailConversationId }) {
  return connectV1Client.get(`EmailConversations/${emailConversationId}`);
}

export function sendMail(conversationId, email) {
  return connectV1Client.post(`Conversations/${conversationId}/EmailConversations`, {
    To: email.To,
    From: email.From,
    Body: email.Body,
    Subject: email.Subject,
  });
}

export function fetchCalls(conversationId, toNumber, from = 0, size = 100) {
  return connectV1Client.post(`AudioConversations/_search`, {
    ConversationId: conversationId,
    ToNumber: toNumber,
    From: from,
    Size: size,
  });
}

export function updateCallHistory(conversationId, call) {
  return connectV1Client.post(`Conversations/${conversationId}/AudioConversations`, {
    To: call.To,
    From: call.From,
    ConversationStatus: call.ConversationStatus,
  });
}

export function updateCallStatus(audioConversationId, conversationStatus) {
  return connectV1Client.patch(`AudioConversations/${audioConversationId}`, {
    ConversationStatus: conversationStatus,
  });
}

export function fetchContact(personId, conversationId) {
  return connectV1Client.get(`Persons/${personId}/Contact/_fetch?conversationId=${conversationId || ''}`);
}

export function getContact(personId) {
  return connectV1Client.get(`Persons/${personId}/Contact`);
}

export function fetchEmailTemplates({
  from,
  size,
  includeSystemTemplate,
  ExcludeTags,
  includeDrip = false,
  languageCode = 'en-US',
}) {
  return connectV1Client.post(`EmailTemplates/_search`, {
    from,
    size,
    includeDrip,
    languageCode,
    includeSystemTemplate,
    ExcludeTags,
  });
}

export function fetchMessageTemplates(from, size, filter = {}) {
  return connectV1Client.post(`MessageTemplates/_search`, {
    from,
    size,
    ...filter,
  });
}

export function fetchCallNotes(conversationId, from, size) {
  return connectV1Client.get(`Conversations/${conversationId}/notes`, {
    params: {
      from,
      size,
    },
  });
}

export function fetchConnectedCount(jobGuids) {
  return connectV1Client.post('Conversations/_stats/connectionstatus', {
    RefIds: jobGuids,
    Status: 'Connected',
  });
}

export function addContact(personId, contactObject) {
  return connectV1Client.put(`Persons/${personId}/Contact`, contactObject);
}

export function getRefundForInvalidContacts(personId) {
  return connectV1Client.post(`Persons/${personId}/Contact/_refund`, {});
}

export function postCallNotes(audioConversationId, note) {
  return connectV1Client.post(`AudioConversations/${audioConversationId}/Notes`, note);
}

export function fetchConnectedJobs(filter) {
  return connectV1Client.post(`ConversationReferences/_search`, {
    From: filter.from,
    Size: filter.size,
    Status: filter.status,
    RefIds: filter.jobGuids,
  });
}

export function fetchConversationReference(conversationReferenceId) {
  return connectV1Client.get(`ConversationReferences/${conversationReferenceId}`);
}

export function putConversationReference(conversationReferenceId, botStatus) {
  return connectV1Client.put(`ConversationReferences/${conversationReferenceId}`, {
    IsBotEnabled: botStatus,
  });
}

export function fetchConnectedIds(refId, from, size, Status) {
  return connectV1Client.post('Persons/_ids', {
    RefId: refId,
    From: from,
    Size: size,
    Status: Status || 'connected',
  });
}

export function fetchBulkPersonDetails(filter) {
  return connectV1Client.post('Persons/_search', filter);
}

export function fetchConversation(refId, from, size) {
  return connectV1Client.post('Persons/_ids', {
    RefId: refId,
    From: from,
    Size: size,
    Status: 'Connected',
  });
}

export function fetchPersonality(personId) {
  return connectV1Client.get(`Persons/${personId}/Personality/_fetch`);
}

export function readPhoneNumberSMS(conversationId, phoneNumber) {
  return connectV1Client.post(`Conversations/${conversationId}/phoneNumber/${phoneNumber}/_read`, {
    Type: 'SMS',
  });
}

export function getPersonality(personId) {
  return connectV1Client.get(`Persons/${personId}/Personality`);
}

export function getPersonDetails(personId) {
  return connectV1Client.get(`Persons/${personId}`);
}

export function getConnectNotifications(from, size) {
  return connectV1Client.get(`Notifications`, {
    params: {
      from,
      size,
    },
  });
}

export function searchConnectNotifications(filter) {
  return connectV1Client.post(`Notifications/_search`, filter);
}

export function getConnectNotificationCount(filter) {
  return connectV1Client.post(`Notifications/_count`, filter);
}

export function updateConversationReadStatus(conversationId, type) {
  return connectV1Client.post(`Conversations/${conversationId}/_read`, {
    Type: type,
  });
}

export function searchConversations(filter) {
  return connectV1Client.post(`Conversations/_search`, filter);
}

export function fetchConversationIds(filter) {
  return connectV1Client.post(`Conversations/_search/ids`, filter);
}

export function fetchBulkUnReadConversationsCount(conversationStatsPayload) {
  return connectV1Client.post(`Conversations/_stats/unreadcounts/`, conversationStatsPayload);
}

export function sendTestEmail(email) {
  return connectV1Client.post('SystemEmails', email);
}

export function getEmailSignatures() {
  return connectV1Client.get(`EmailSignatures`);
}

export function postEmailSignature(EmailId, Signature) {
  return connectV1Client.post(`EmailSignatures`, {
    EmailId,
    Signature,
  });
}

export function postEmailSignatures(emailSignatures) {
  return connectV1Client.post(`EmailSignatures/_bulk`, {
    Signatures: emailSignatures,
  });
}

export function fetchConversations(conversationId) {
  return connectV1Client.get(`Conversations/${conversationId}`);
}

export function fetchUserEmails({ testEmailStatus, isOrgDefault } = {}) {
  return connectV1Client.get('email', {
    params: {
      isOrgDefault,
      testEmailStatus,
    },
  });
}
export function addUserEmail(emailId, senderName) {
  return connectV1Client.post('email', {
    EmailId: emailId,
    SenderName: senderName,
  });
}

export function patchUpdateUserEmail(id, emailAddress) {
  const emailPayload = Object.keys(emailAddress).map(key => ({ op: 'add', path: key, value: emailAddress[key] }));
  return connectV1Client.patch(`email/${id}`, emailPayload);
}

export function deleteUserEmail(id) {
  return connectV1Client.delete(`email/${id}/_delete`);
}

export function fetchEmailStats(conversationIds) {
  return connectV1Client.post(`emailConversations/_emailstats`, {
    ConversationIds: conversationIds,
  });
}
export function fetchConversationStats(conversationIds, preferredStats) {
  return connectV1Client.post(`Conversations/_conversationStats`, {
    ConversationIds: conversationIds,
    PreferredStats: preferredStats,
  });
}
export function fetchSubscriptionStats(personIds, jobGuid) {
  return connectV1Client.post(`Persons/subscriptionStats/_fetch`, { personIds, RefId: jobGuid });
}
export function fetchJobDripStats(jobGuid) {
  return connectV1Client.post(`EmailConversations/_dripEmailStats`, {
    refid: jobGuid,
  });
}

export function updateEmailMismatchStatus(personId, email, validationStatus, mismatchReason) {
  return connectV1Client.post(`persons/${personId}/email/mismatch`, {
    Contact: email,
    IsMismatch: true,
    MismatchUpdateReason: mismatchReason,
  });
}

export function updatePhoneMismatchStatus(personId, phoneNumber, validationStatus, mismatchReason) {
  return connectV1Client.post(`persons/${personId}/phone/mismatch`, {
    Contact: phoneNumber,
    IsMismatch: true,
    MismatchUpdateReason: mismatchReason,
  });
}

export function unsubscribeCandidate(personId, unsubscribeReason, conversationId, subscriptionStatus) {
  return connectV1Client.post(`persons/${personId}/subscription`, {
    ConversationId: conversationId,
    SubscriptionStatus: subscriptionStatus,
    SubscriptionRemark: unsubscribeReason,
  });
}
export function GetOrgPhoneConsentInfo(phoneNumbers, personId) {
  return connectV1Client.post(`/Contact/phone/consent`, {
    PhoneNumbers: phoneNumbers,
  });
}
